import React, {ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {QuoteBuilderData, QuoteBuilderInventory} from "../../../interfaces/QuoteBuilderData";
import {selectArrowSvg} from "../../../helpers/SvgTheming";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {Option} from "../../../models/Inputs/Option";
import "./QuoteBuilderForm.scss";
import CurrenciesComponent from "../../Currencies/Currencies";
import {convert, getCurrencySymbol} from "../../../helpers/Currencies";
import {postInventoriesDownload} from "../../../@api/Inventory";
import {downloadFile} from "../../../helpers/DownloadFile";
import {dateToLocaleString} from "../../../helpers/DateFormatter";

interface Props {
    data: QuoteBuilderData[];
    setData: Dispatch<SetStateAction<QuoteBuilderData[]>>;
    selectedInventory: number;
    setSelectedInventory: Dispatch<SetStateAction<number>>;
}

const QuoteBuilderForm: FC<Props> = ({
                                         data,
                                         setData,
                                         selectedInventory,
                                         setSelectedInventory
                                     }) => {
    const options = [
        {name: '', value: ''},
        {name: 'EUR', value: 'EUR'},
        {name: 'GBP', value: 'GBP'},
        {name: 'USD', value: 'USD'},

    ];

    const user = useSelector((state: RootState) => state.userSlice.user);
    const currencies = useSelector((state: RootState) => state.currenciesSlice.currencies);

    const [commissionType, setCommissionType] = useState<string>('unit');
    const [quantityOptions, setQuantityOptions] = useState<Option[]>([]);

    useEffect(() => {
        document.getElementsByClassName('modal-dialog')[0].classList.add('modal-lg');

        if (data.length) {
            setQuantityOptions(addQuantityOptions(Object.values(Object.values(data)[0].inventories)[0]));
        }
    }, []);

    const addQuantityOptions = (inventory: QuoteBuilderInventory) => {
        let tempOptions: Option[] = [{name: '', value: ''}];

        if (!((inventory.split_type === 'NEVERLEAVEONE' && inventory.quantity > 1) ||
            (inventory.split_type === 'CUSTOM' && !inventory.custom_split?.[1])))
            tempOptions.push({name: String(1), value: String(1)});

        for (let i = 2; i <= inventory.quantity; i++) {
            if (inventory.split_type === 'CUSTOM') {
                if (inventory.custom_split && inventory.custom_split[i]) {
                    tempOptions.push({name: String(i), value: String(i)});
                }
            } else {
                tempOptions.push({name: String(i), value: String(i)});
            }
        }

        return tempOptions;
    };

    const handleChoose = (element: ChangeEvent<HTMLInputElement>) => {
        const tempId = Number(element.target.getAttribute('data-quote-inventory-id'));

        setSelectedInventory(tempId);

        data.forEach((event) => {
            event.inventories.forEach((inventory) => {
                if (inventory.id === tempId) {
                    setQuantityOptions(addQuantityOptions(inventory));
                }
            });
        });
    };

    const handleChangeCommission = (isIncrease: boolean) => {
        const element = document.getElementsByName('commission')[0] as HTMLInputElement;
        let value = (isIncrease) ? Number(element.value) + 1 : Number(element.value) - 1;

        validateCommissionValue(value, element);
    };

    const handleInputCommission = (element: ChangeEvent<HTMLInputElement>) => {
        let value = Number(element.target.value);

        validateCommissionValue(value, element.target);
    };

    const validateCommissionValue = (value: number, element: HTMLInputElement) => {
        if (value < 0) {
            value = 0;
        } else if (value > 99999999) {
            value = 99999999;
        }

        element.value = String(value);

        data.forEach((event) => {
            event.inventories.forEach((inventory) => {
                if (inventory.id === selectedInventory) {
                    if (commissionType === 'unit') {
                        inventory.new_price += (value - inventory.commission);
                        inventory.commission = value;
                    } else if (commissionType === 'percent') {
                        inventory.new_price = inventory.new_price * 100 / (100 + inventory.commission) + (inventory.new_price * 100 / (100 + inventory.commission)) * value / 100;
                        inventory.commission = value;
                    }
                }
            });
        });

        setData([...data]);
    };

    const handleRoundUpPrice = (isUp: boolean) => {
        data.forEach((event) => {
            event.inventories.forEach((inventory) => {
                if (inventory.id === selectedInventory) {
                    if (isUp) {
                        inventory.new_price = Math.ceil(inventory.new_price / 10) * 10;
                    } else {
                        inventory.new_price = Math.floor(inventory.new_price / 10) * 10;
                    }
                }
            });
        });

        setData([...data]);
    };

    const handleSelectCurrency = (element: ChangeEvent<HTMLSelectElement>) => {
        if (!element.target.value) return;

        data.forEach((event) => {
            event.inventories.forEach((inventory) => {
                if (inventory.id === selectedInventory) {
                    inventory.new_price = convert(currencies, inventory.new_currency, element.target.value, inventory.new_price);
                    inventory.new_currency = element.target.value;
                }
            });
        });

        setData([...data]);
    };
    const handleQuantityClick = (element: React.MouseEvent<HTMLSelectElement>) => {
        data.forEach((event) => {
            event.inventories.forEach((inventory) => {
                const newQuantity = Number((element.target as HTMLSelectElement).value);

                if (inventory.id === selectedInventory && newQuantity) {
                    inventory.quantity = newQuantity;
                }
            });
        });

        setData([...data]);
    };

    const handleChangeCommissionType = (element: ChangeEvent<HTMLInputElement>) => {
        const input = document.getElementsByName('commission')[0] as HTMLInputElement;
        const type = element.target.value;

        data.forEach((event) => {
            event.inventories.forEach((inventory) => {
                inventory.commission = 0;
            });
        });

        setData([...data]);
        input.value = '0';
        setCommissionType(type);
    };

    const download = () => {
        const notes = document.getElementsByName('notes')[0] as HTMLTextAreaElement;

        if (user) {
            postInventoriesDownload(user.id, data, notes.value)
                .then(response => {
                    const href = URL.createObjectURL(response.data);
                    const title = user.domain + '_' + new Date().toLocaleDateString() + '.pdf';

                    downloadFile(title, href);
                })
                .catch(() => {
                    alert('You should select inventories');
                });
        }
    };

    return (
        <React.Fragment>
            {data.length
                ? <div className="quote">
                    {data.map((event) => (
                        <ul className="quote__event_ul" key={event.event_id}>
                            <li className="quote__event_li">{event.name}, {event.venue}, {event.city}, {dateToLocaleString(event.occurs_at)}
                                <ul className="quote__inventory_ul">
                                    {event.inventories.map((inventory) => (
                                        <li
                                            className="quote__inventory_li"
                                            key={inventory.id}
                                        >
                                            <input
                                                className="quote__inventory_input"
                                                type="radio"
                                                name="inventory-quote"
                                                id={"inventory" + inventory.id}
                                                data-quote-event-id={event.event_id}
                                                data-quote-inventory-id={inventory.id}
                                                defaultChecked={inventory.id === selectedInventory}
                                                onChange={handleChoose}
                                            />
                                            <label
                                                className="quote__inventory_label"
                                                htmlFor={"inventory" + inventory.id}
                                            >
                                                {inventory.inventory_id} ({inventory.quantity})
                                                <div>
                                                    <span className="old-price">
                                                        ({getCurrencySymbol(currencies, inventory.old_currency)}{inventory.old_price})
                                                    </span>
                                                    <span> </span>
                                                    <span>
                                                        {getCurrencySymbol(currencies, inventory.new_currency)}{Math.round(inventory.new_price * 100) / 100}
                                                    </span>
                                                </div>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    ))}
                </div>
                : <div className="quote h-100">
                    No selected inventories
                </div>
            }
            <div className="quote__form">
                <>
                    <div className="quote__fields gap-lg-3">
                        <div className="quote__field">
                            <p className="quote__field_title">Select currency</p>
                            <select
                                className="input select"
                                style={{backgroundImage: `url('${selectArrowSvg(user?.color)}')`}}
                                onChange={handleSelectCurrency}
                            >
                                {
                                    options.map((option: Option) => (
                                        <option key={option.name} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="quote__field">
                            <p className="quote__field_title">Quantity</p>
                            <div className="quote__field__wrapper">
                                <select className="input select" name="quantity" onClick={handleQuantityClick}>
                                    {
                                        quantityOptions.map((option: Option) => (
                                            <option key={option.name} value={option.value}>
                                                {option.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="quote__field">
                            <p className="quote__field_title">Commission amount</p>
                            <div className="quote__field__wrapper gap-0">
                                <button className="button button_submit" type="button"
                                        onClick={() => handleChangeCommission(false)}>-
                                </button>
                                <input className="input" type="number" name="commission" defaultValue="0"
                                       onChange={handleInputCommission}/>
                                <button className="button button_submit" type="button"
                                        onClick={() => handleChangeCommission(true)}>+
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="quote__fields">
                        <div className="quote__field">
                            <p className="quote__field_title">Pricing (round up or down)</p>
                            <div className="quote__field__wrapper">
                                <button className="button" type="button"
                                        onClick={() => handleRoundUpPrice(true)}>
                                    <img className="arrow-up" src={selectArrowSvg(user?.color)}
                                         alt="Filters"/>
                                </button>
                                <button className="button" type="button"
                                        onClick={() => handleRoundUpPrice(false)}>
                                    <img src={selectArrowSvg(user?.color)} alt="Filters"/>
                                </button>
                            </div>
                        </div>
                        <div className="quote__field">
                            <p className="quote__field_title">Commission type</p>
                            <div className="quote__field__wrapper">
                                <input
                                    className="quote__inventory_input"
                                    type="radio"
                                    id="unit"
                                    name="commission"
                                    value="unit"
                                    onChange={handleChangeCommissionType}
                                    checked={commissionType === 'unit'}
                                />
                                <label className="button quote__inventory_switch-button" htmlFor="unit">$</label>
                                <input
                                    className="quote__inventory_input"
                                    type="radio"
                                    id="percent"
                                    name="commission"
                                    value="percent"
                                    onChange={handleChangeCommissionType}
                                    checked={commissionType === 'percent'}
                                />
                                <label className="button quote__inventory_switch-button" htmlFor="percent">%</label>
                            </div>
                        </div>
                    </div>
                    <div className="quote__fields">
                        <div className="quote__field">
                            <p className="quote__field_title">Additional notes</p>
                            <textarea className="input quote__inventory_textarea" name="notes"></textarea>
                        </div>
                    </div>
                    <button
                        className="button button_submit"
                        type="button"
                        onClick={() => download()}
                    >
                        Download
                    </button>
                </>
            </div>
            <CurrenciesComponent/>
        </React.Fragment>
    )
}

export default QuoteBuilderForm;
