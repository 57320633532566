import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {User} from "../../models/User";

export interface UserState {
    user: User | undefined | null
}

const initialState: UserState = {
    user: undefined
};

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setUserState: (state: UserState, action: PayloadAction<UserState>) => {
            state.user = action.payload.user;
        }
    }
});

export const {setUserState} = userSlice.actions;

export default userSlice.reducer;
