import {Form, Formik, FormikValues} from "formik";
import React, {ReactElement, useState} from "react";
import "./TableFilters.scss";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {filtersBurgerSvg} from "../../helpers/SvgTheming";
import {TextInput} from "../../models/Inputs/TextInput";
import renderInputs from "../../helpers/RenderInputs";

interface TableFiltersProps<T> {
    filterInitial: T,
    setDataFunction: (values: T) => void,
    inputsFirst: TextInput[],
    inputsSecond: TextInput[],
    inputsThird: TextInput[],
}

const TableFilters = <T extends unknown>({
                                             filterInitial,
                                             setDataFunction,
                                             inputsFirst,
                                             inputsSecond,
                                             inputsThird
                                         }: TableFiltersProps<T>): ReactElement => {
    const user = useSelector((state: RootState) => state.userSlice.user);
    const [filtersVisibility, setFiltersVisibility] = useState<boolean>(false);

    const handleFiltersVisibility = () => {
        const filters = document.getElementsByClassName('filters__wrapper-secondary')[0];

        filtersVisibility ? filters.classList.add('visually-hidden') : filters.classList.remove('visually-hidden');
        setFiltersVisibility(!filtersVisibility);
    };

    const handleFiltersSubmit = (values: FormikValues) => {
        if (values.to_date) {
            const userTimezoneOffset = values.to_date.getTimezoneOffset() * 60000;

            values.to_date = new Date(values.to_date.getTime() - userTimezoneOffset);
        }

        setDataFunction(values as T);
    };

    return (
        <>
            <Formik
                initialValues={filterInitial as FormikValues}
                onSubmit={(values) => {
                    handleFiltersSubmit(values);
                }}>
                <Form className="filters gap-2">
                    <div className="filters__wrapper-primary">
                        {renderInputs(inputsFirst, 'inputsFirst', 'filter-container', 'col')}
                        <div className="filters__wrapper-buttons col-lg-3 justify-content-end">
                            <button className="button button_submit" type="submit">
                                Submit
                            </button>
                            <button className="button d-flex gap-1 align-items-center justify-content-center" type="button" onClick={handleFiltersVisibility}>
                                <img src={filtersBurgerSvg(user?.color)} alt="Filters"/>
                                <div className={'d-sm-none d-md-inline flex-shrink-0'}>Filters</div>
                            </button>
                        </div>
                    </div>
                    {renderInputs(
                        [...inputsSecond, ...inputsThird],
                        '2',
                        'flex-row cols filters__wrapper-secondary visually-hidden flex-wrap',
                        'col-12 col-sm-6 col-md-4 col-lg-3'
                    )}
                </Form>
            </Formik>
        </>
    )
}

export default TableFilters
