import {Event} from "../../../models/Event";
import {Inventory} from "../../../models/Inventory";
import React, {FC, FormEvent, useState} from "react";
import {Form, Formik} from "formik";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import "./HoldForm.scss"
import {postInventoriesHold} from "../../../@api/Inventory";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {InitialValuesTypes} from "../../../interfaces/InitialValuesTypes";
import renderInputs from "../../../helpers/RenderInputs";

interface Props {
    event: Event,
    inventory: Inventory,
    showHandle: () => void,
}

const HoldForm: FC<Props> = ({event, inventory, showHandle}) => {
    const initialValues = {quantity: '', seats: '', expiry_date: '', display_name: '', email: '', phone: ''};
    const holdInputsSecond = [
        {type: 'text', name: 'display_name', placeholder: 'Name'},
        {type: 'email', name: 'email', placeholder: 'Email'},
        {type: 'phone', name: 'phone', placeholder: 'Phone Number'},
    ];
    const user = useSelector((state: RootState) => state.userSlice.user);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const onQuantityChange = (event: FormEvent<HTMLSelectElement>) => {
        const target = event.target as HTMLSelectElement;
        const select = document.getElementsByName('seats')[0] as HTMLSelectElement;
        let value = Number(target.value);

        while (select.options.length > 0) {
            select.remove(0);
        }

        select.add(new Option('', ''));

        if (value === 1) {
            for (let i = inventory.low_seat; i <= inventory.high_seat; i++) {
                select.add(new Option(`${i}-${i}`, `${i}-${i}`));
            }
        } else if (value !== 0) {
            const groupCount = inventory.high_seat - inventory.low_seat - value + 1;

            for (let i = 0; i <= groupCount; i++) {
                const seats = `${inventory.low_seat + i}-${inventory.low_seat + i + value - 1}`;

                select.add(new Option(seats, seats));
            }
        }
    };

    const quantityOptions = () => {
        let tempOptions: { name: string, value: string }[] = [{name: '', value: ''}];
        const quantity = (inventory.shown_quantity < inventory.quantity && inventory.shown_quantity)
            ? inventory.shown_quantity
            : inventory.quantity;

        if (!((inventory.split_type === 'NEVERLEAVEONE' && quantity > 1) ||
            (inventory.split_type === 'CUSTOM' && !inventory.custom_split?.[1])))
            tempOptions.push({name: String(1), value: String(1)});

        for (let i = 2; i <= quantity; i++) {
            if (inventory.split_type === 'CUSTOM') {
                if (inventory.custom_split && inventory.custom_split[i]) {
                    tempOptions.push({name: String(i), value: String(i)});
                }
            } else {
                tempOptions.push({name: String(i), value: String(i)});
            }
        }

        return tempOptions;
    };

    const holdInputsFirst = [
        {
            type: 'select',
            name: 'quantity',
            placeholder: 'Quantity',
            onSelect: onQuantityChange,
            options: quantityOptions()
        },
        {
            type: 'select', name: 'seats', placeholder: 'Seats',
            options: [],
        },
        {type: 'date', name: 'expiry_date', placeholder: 'Expiry Date', showTimeSelect: true},
    ];

    const handleHoldSubmit = (values: InitialValuesTypes) => {
        if (user) {
            const userTimezoneOffset = new Date(values.expiry_date).getTimezoneOffset() * 60000;

            values.expiry_date = new Date(new Date(values.expiry_date).getTime() - userTimezoneOffset);

            postInventoriesHold(user.id, inventory.id, values)
                .then(() => {
                    showHandle();
                })
                .catch((e: Error) => {
                    alert(e);
                });
        }
    };

    const validate = (values: InitialValuesTypes) => {
        let haveAnError = false;

        for (const [key, value] of Object.entries(values)) {
            if (!value) {
                haveAnError = true;
            }

            if (key === 'seats') {
                const select = document.getElementsByName(key)[0] as HTMLSelectElement;

                if (select.value === '') {
                    haveAnError = true;
                }
            }
        }

        setIsDisabled(haveAnError);
    };

    return (
        <>
            <div className="hold hold__info__event">
                <div className="hold__info__event_name">{event.name} | {event.venue} | {event.city}</div>
                <div className="hold__info__event_date"> {dateToLocaleString(event.occurs_at)}</div>
            </div>
            <div className="hold hold__info__inventory">
                <div className="hold__info__inventory_item">
                    <div className="hold__info__inventory_title">Quantity</div>
                    <div className="hold__info__inventory_value">
                        {(inventory.shown_quantity < inventory.quantity && inventory.shown_quantity) ? inventory.shown_quantity : inventory.quantity}
                    </div>
                </div>
                <div className="hold__info__inventory_item">
                    <div className="hold__info__inventory_title">Section</div>
                    <div className="hold__info__inventory_value">{inventory.section}</div>
                </div>
                <div className="hold__info__inventory_item">
                    <div className="hold__info__inventory_title">Row</div>
                    <div className="hold__info__inventory_value">{inventory.row}</div>
                </div>
                <div className="hold__info__inventory_item">
                    <div className="hold__info__inventory_title">Price</div>
                    <div className="hold__info__inventory_value">{inventory.unit_amount}</div>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values: InitialValuesTypes) => handleHoldSubmit(values)}
                validate={(values: InitialValuesTypes) => validate(values)}
                enableReinitialize>
                <Form className="hold hold__form">
                    <div className="hold__form_title">Hold until:</div>
                    {renderInputs([...holdInputsFirst, ...holdInputsSecond], 'holdInputs', 'my-2 d-flex flex-row flex-wrap cols ', 'col-12 col-lg-6')}
                    <button className="button button_submit modal-submit" type="submit" disabled={isDisabled}>
                        Submit
                    </button>
                </Form>
            </Formik>
        </>
    )
}

export default HoldForm;
