import {Route, Routes} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ErrorPage from "../pages/ErrorPage";
import LoadingPage from "../pages/LoadingPage";
import {setUser} from "../helpers/User";
import MainLayout from "../layouts/MainLayout/MainLayout";
import InventoriesTable from "../components/InventoriesTable/InventoriesTable";
import {RootState} from "../store/store";

const Router = () => {
    const user = useSelector((state: RootState) => state.userSlice.user);

    const dispatch = useDispatch();

    useEffect(() => {
        setUser(dispatch);
    }, [dispatch])

    return (
        <Routes>
            <Route path="/" element={
                !!user ? (
                    <MainLayout>
                        <InventoriesTable/>
                    </MainLayout>
                ) : ( user === undefined ? <LoadingPage/> : <ErrorPage/> )
            }/>

            <Route path="/*" element={<ErrorPage/>}/>
        </Routes>
    )
}

export default Router;
