import {TextInput} from "../models/Inputs/TextInput";
import InputSwitch from "../ui/Inputs/InputSwitch/InputSwitch";
import React from "react";

const renderInputs = (items: TextInput[], key: string, wrapperClass = '', inputWrapperClass = '') => (
    <div className={wrapperClass}>
        {items.map((value: TextInput, index: number) => (
            <div className={'d-flex flex-row align-items-center ' + inputWrapperClass} key={`${key}-${value.name}-${index}`}>
                {!value.noLabel && <label className={'col-4'}>{value.placeholder}</label>}
                {InputSwitch({...value, placeholder: !!value.noLabel ? value.placeholder : undefined})}
            </div>
        ))}
    </div>
)

export default renderInputs;
